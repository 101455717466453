/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { documentsApi } from '@b2d/redux/subReducers/Offers';
import { legalAdvertApi } from '@b2d/redux/subReducers/AnnoncesLegales';
import offerCacheSlice from '@europrocurement/l2d-domain/reducers/offre/slices/offerCacheSlice';
import { AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem } from '@europrocurement/l2d-domain/openApi/ApiAnnoncesLegales';
import { departmentApi } from '@b2d/redux/subReducers/Geoloc';
import { createFetchActions } from './createFetchActions';
import { GroupedSelectOptions } from '../forms/types';

const fetchActions = {
    documents: () => documentsApi.apiTypesDocumentsGetCollection({ itemsPerPage: 1000 }),
    subTypes: () =>
        legalAdvertApi.getListAnnoncesLegalesAnnonceLegaleCollection({ itemsPerPage: 1000 }),
    departments: () => departmentApi.apiDepartementsGetCollection({ itemsPerPage: 1000 }),
};

/** Offer cached data redux store managment  :
 * Todo : - Refactor useLoadStore to move here all offer related data that's need to be fetched once at module loading (article specifics, ...)
 */
const useOfferCache = () => {
    const {
        useDocuments,
        useSubTypes,
        // useDepartments
    } = createFetchActions(fetchActions);
    const { result: docResult, isLoaded: docLoaded, isFetching: docFetching } = useDocuments;
    const {
        result: subTypesResult,
        isLoaded: subTypesLoaded,
        isFetching: subTypesFetching,
    } = useSubTypes;

    const tmpHardCodedDepartmentOptions = [
        { value: '01', label: '	Ain' },
        { value: '02', label: '	Aisne' },
        { value: '03', label: '	Allier' },
        { value: '04', label: '	Alpes-de-Haute-Provence' },
        { value: '05', label: '	Hautes-Alpes' },
        { value: '06', label: '	Alpes-Maritimes' },
        { value: '07', label: '	Ardèche' },
        { value: '08', label: '	Ardennes' },
        { value: '09', label: '	Ariège' },
        { value: '10', label: '	Aube' },
        { value: '11', label: '	Aude' },
        { value: '12', label: '	Aveyron' },
        { value: '13', label: '	Bouches-du-Rhône' },
        { value: '14', label: '	Calvados' },
        { value: '15', label: '	Cantal' },
        { value: '16', label: '	Charente' },
        { value: '17', label: '	Charente-Maritime' },
        { value: '18', label: '	Cher' },
        { value: '19', label: '	Corrèze' },
        { value: '21', label: "	Côte-d'Or" },
        { value: '22', label: "	Côtes-d'Armor" },
        { value: '23', label: '	Creuse' },
        { value: '24', label: '	Dordogne' },
        { value: '25', label: '	Doubs' },
        { value: '26', label: '	Drôme' },
        { value: '27', label: '	Eure' },
        { value: '28', label: '	Eure-et-Loir' },
        { value: '29', label: '	Finistère' },
        { value: '2A', label: '	Corse-du-Sud' },
        { value: '2B', label: '	Haute-Corse' },
        { value: '30', label: '	Gard' },
        { value: '31', label: '	Haute-Garonne' },
        { value: '32', label: '	Gers' },
        { value: '33', label: '	Gironde' },
        { value: '34', label: '	Hérault' },
        { value: '35', label: '	Ille-et-Vilaine' },
        { value: '36', label: '	Indre' },
        { value: '37', label: '	Indre-et-Loire' },
        { value: '38', label: '	Isère' },
        { value: '39', label: '	Jura' },
        { value: '40', label: '	Landes' },
        { value: '41', label: '	Loir-et-Cher' },
        { value: '42', label: '	Loire' },
        { value: '43', label: '	Haute-Loire' },
        { value: '44', label: '	Loire-Atlantique' },
        { value: '45', label: '	Loiret' },
        { value: '46', label: '	Lot' },
        { value: '47', label: '	Lot-et-Garonne' },
        { value: '48', label: '	Lozère' },
        { value: '49', label: '	Maine-et-Loire' },
        { value: '50', label: '	Manche' },
        { value: '51', label: '	Marne' },
        { value: '52', label: '	Haute-Marne' },
        { value: '53', label: '	Mayenne' },
        { value: '54', label: '	Meurthe-et-Moselle' },
        { value: '55', label: '	Meuse' },
        { value: '56', label: '	Morbihan' },
        { value: '57', label: '	Moselle' },
        { value: '58', label: '	Nièvre' },
        { value: '59', label: '	Nord' },
        { value: '60', label: '	Oise' },
        { value: '61', label: '	Orne' },
        { value: '62', label: '	Pas-de-Calais' },
        { value: '63', label: '	Puy-de-Dôme' },
        { value: '64', label: '	Pyrénées-Atlantiques' },
        { value: '65', label: '	Hautes-Pyrénées' },
        { value: '66', label: '	Pyrénées-Orientales' },
        { value: '67', label: '	Bas-Rhin' },
        { value: '68', label: '	Haut-Rhin' },
        { value: '69', label: '	Rhône' },
        { value: '70', label: '	Haute-Saône' },
        { value: '71', label: '	Saône-et-Loire' },
        { value: '72', label: '	Sarthe' },
        { value: '73', label: '	Savoie' },
        { value: '74', label: '	Haute-Savoie' },
        { value: '75', label: '	Paris' },
        { value: '76', label: '	Seine-Maritime' },
        { value: '77', label: '	Seine-et-Marne' },
        { value: '78', label: '	Yvelines' },
        { value: '79', label: '	Deux-Sèvres' },
        { value: '80', label: '	Somme' },
        { value: '81', label: '	Tarn' },
        { value: '82', label: '	Tarn-et-Garonne' },
        { value: '83', label: '	Var' },
        { value: '84', label: '	Vaucluse' },
        { value: '85', label: '	Vendée' },
        { value: '86', label: '	Vienne' },
        { value: '87', label: '	Haute-Vienne' },
        { value: '88', label: '	Vosges' },
        { value: '89', label: '	Yonne' },
        { value: '90', label: '	Territoire de Belfort' },
        { value: '91', label: '	Essonne' },
        { value: '92', label: '	Hauts-de-Seine' },
        { value: '93', label: '	Seine-Saint-Denis' },
        { value: '94', label: '	Val-de-Marne' },
        { value: '95', label: "	Val-d'Oise" },
        { value: '971', label: '	Guadeloupe' },
        { value: '972', label: '	Martinique' },
        { value: '973', label: '	Guyane' },
        { value: '974', label: '	La Réunion' },
        { value: '975', label: '	Saint Pierre et Miquelon' },
        { value: '976', label: '	Mayotte' },
        { value: '977', label: '	Saint-Barthélémy' },
        { value: '978', label: '	Saint-Martin' },
        { value: '986', label: ' 	 Wallis-et-Futuna' },
        { value: '987', label: '	Polynésie Française' },
        { value: '988', label: '	Nouvelle Calédonnie' },
        { value: '99', label: '	Journal Officiel' },
        { value: '991', label: '	France, avis national' },
        { value: '995', label: '	DEP Cahier Special' },
        { value: '999', label: '	France' },
    ];

    // const {
    //     result: departmentsResult,
    //     isLoaded: departmentsLoaded,
    //     isFetching: departmentsFetching,
    // } = useDepartments;

    const dispatch = useDispatch();

    const newItem = ({ libelle, id }: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem) => ({
        label: libelle ?? '',
        value: id,
    });

    const createCategory = (
        addedItem: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem,
        list: GroupedSelectOptions,
    ) => {
        list.push({
            label: addedItem.categorie?.libelle ?? '',
            options: [newItem(addedItem)],
        });
    };

    const addSubTypeInCategory = (
        categoryLabel: string,
        addedItem: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem,
        list: GroupedSelectOptions,
    ) => {
        const categoryIndex = list.findIndex((category) => category.label === categoryLabel);
        if (categoryIndex === -1) {
            addSubTypeInCategory('Autres', addedItem, list);
        } else {
            list[categoryIndex].options.push(newItem(addedItem));
        }
    };

    const defaultOptions = [
        {
            label: 'Autres',
            options: [],
        },
    ];

    const groupSubtypesByCategories = () => {
        const categories = (
            subTypesResult as AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem[]
        ).reduce((acc: GroupedSelectOptions, item) => {
            const itemCategory = item.categorie?.libelle;
            const categoryExist = acc.some(({ label }) => label === itemCategory);
            if (!itemCategory) {
                addSubTypeInCategory('Autres', item, acc);
            } else if (!categoryExist) {
                createCategory(item, acc);
            } else {
                addSubTypeInCategory(itemCategory, item, acc);
            }
            return acc;
        }, defaultOptions);
        return categories;
    };

    useEffect(() => {
        useDocuments.fetch();
        useSubTypes.fetch();
        // useDepartments.fetch();

        dispatch(offerCacheSlice.actions.updateCachedDepartments(tmpHardCodedDepartmentOptions));
    }, []);

    useEffect(() => {
        if (docLoaded && !docFetching) {
            dispatch(offerCacheSlice.actions.updateCachedDocuments(docResult as Array<unknown>));
        }
    }, [docResult, docLoaded, docFetching]);

    useEffect(() => {
        if (subTypesLoaded && !subTypesFetching) {
            const cachedSubTypesOptions = groupSubtypesByCategories();
            dispatch(
                offerCacheSlice.actions.updateCachedSubTypes(
                    cachedSubTypesOptions as Array<unknown>,
                ),
            );
        }
    }, [subTypesResult, subTypesLoaded, subTypesFetching]);

    // useEffect(() => {
    //     if (departmentsLoaded && !departmentsFetching) {
    //         console.log('departmentsResult', departmentsResult);
    //         // TODO Format and store as options
    //         dispatch(
    //             offerCacheSlice.actions.updateCachedDepartments(tmpHardCodedDepartmentOptions),
    //         );
    //     }
    // }, [departmentsResult, departmentsLoaded, departmentsFetching]);
};
export default useOfferCache;
