import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import asyncDispatchMiddleware from 'async-dispatch';
import {
    // Reducers statiques :
    RoutingReducer,

    // Tiers :
    ConfigurationParameters,
    createTiersReducer,
    PrescripteursApi,
    Configuration,
    TIERS_REDUCER_NAME,
    PAYS_SLICE_NAME,
    WALLET_OPTIONS_SLICE_NAME,
    CIVILITIES_SLICE_NAME,
    CSP_OPTIONS_SLICE_NAME,
    FOURNISSEUR_SLICE_NAME,
    PRESCRIPTEUR_SLICE_NAME,
    TIERS_FOURNISSEUR_SLICE_NAME,
    LogoApi,
    CategorieSocioProfessionnelleApi,
    CivilitesApi,
    PortefeuilleApi,
    CoordonneesApi,

    // Mail :
    ConfigurationParameters as MailConfigurationParameters,

    // Achats :
    createMediaobjectReducer,
    createFactureAchatReducer,
    createCategorieStatsReducer,
    createCodeRejetStatsReducer,
    WorkflowMailApi,
    MediaObjectApi,
    FactureAchatApi,
    FactureAchatNoteApi,
    ACHATS_REDUCER_NAME,
    CATEGORIE_STATS_SLICE_NAME,
    CODE_REJET_STATS_SLICE_NAME,
    FACTURES_ACHATS_SLICE_NAME,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
    MEDIAOBJECT_SLICE_NAME,

    // Dossiers :
    createRubriqueFacturationReducer,
    createTauxTvaReducer,
    createJournalReducer,
    createDossierReducer,
    createFactureVenteReducer,
    createCommandeReducer,
    ContactsApi,
    DOSSIERS_REDUCER_NAME,
    COMMANDES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    FACTURE_VENTE_SLICE_NAME,
    JOURNAL_SLICE_NAME,
    RUBFACT_SLICE_NAME,
    TXTVA_SLICE_NAME,
    AdressesApi,
    APIENV,
    FACTURES_ACHATS_PAUSE_DATASOURCE_NAME,
    FactureAchatLigneApi,
    DossierApi,

    // Formalités :
    FORMALITES_REDUCER_NAME,

    // Offres :
    OFFERS_SLICE_NAME,
    OFFRE_REDUCER_NAME,
    CATALOGS_SLICE_NAME,
    PACKAGES_SLICE_NAME,
    ARTICLES_SLICE_NAME,
    OFFER_OPTIONS_SLICE_NAME,
    OFFER_ADDITIONAL_FIELDS_SLICE_NAME,
    DOCUMENT_SLICE_NAME,
    APIS_ENUM,

    // Others ...
    FACTURATION_REDUCER_NAME,
    createReleveAchatReducer,
    FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME,
    SIGNATURE_REDUCER_NAME,
    MailApi,
    getCustomiserState,
    FormaliteConfigurationParameters,
    FORMULAIRE_REDUCER_NAME,
    UtilisateurApi,
} from '@europrocurement/l2d-domain';

import { CUSTOMIZER_REDUCER_NAME, CustomizerReducer } from '@europrocurement/l2d-modules';

import {
    CategorieSocioProfessionnelleJsonldTiersRead,
    PortefeuilleJsonldTiersRead,
    TblParametreReferencesCiviliteJsonldCiviliteReadCollection,
    TblPaysJsonldPaysRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { TauxTvaApi } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import { VATS_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/offre/slices/vatsSlice';
import { DOMAINS_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/offre/slices/domainsSlice';

import { INVOICE_STATEMENT_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/achats/slices/invoiceStatementSlice';

import { MediaObjectNoteApi, ReleveAchatApi } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    ViewFunctionMap,
    ViewName,
} from '@b2d/pages/Achats/views/lists/UnregisteredInvoicesViews/hooks/types';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { FactureFormReducer } from './FactureFormReducer';

// Keycloak token
import { updateToken } from '../keycloak';

import { combinedFactureFormaliteReducer } from './subReducers/FactureFormalite/Reducer';
import { combinedDossierSignatureReducer } from './subReducers/DossierSignature/Reducer';
// eslint-disable-next-line import/no-cycle
import { combinedFormaliteReducer } from './subReducers/FormalityReducer';
import { combinedOfferReducers } from './subReducers/Offers/reducers';
// eslint-disable-next-line import/no-cycle
import { combinedFormulaireReducer } from './subReducers/FormulaireReducer';

export { customizerSelector } from '@europrocurement/l2d-modules';

const getBasePathFromApiName = (name: APIS_ENUM) =>
    APIENV.apis[name][getCustomiserState().api[name]];

const getApiConfiguration: (name: APIS_ENUM) => ConfigurationParameters = (name: APIS_ENUM) => ({
    accessToken: updateToken,
    basePath: getBasePathFromApiName(name),
});

const configTiers: ConfigurationParameters = getApiConfiguration(APIS_ENUM.TIERS);
const configAchats: ConfigurationParameters = getApiConfiguration(APIS_ENUM.ACHATS);
const configDossiers: ConfigurationParameters = getApiConfiguration(APIS_ENUM.DOSSIERS);
const configUtilisateurs: ConfigurationParameters = getApiConfiguration(APIS_ENUM.UTILISATEUR);

export const prescribersApi = new PrescripteursApi(new Configuration(configTiers));
export const coordonneesApi = new CoordonneesApi(new Configuration(configTiers));
export const contactsApi = new ContactsApi(new Configuration(configTiers));
export const adressesApi = new AdressesApi(new Configuration(configTiers));
export const categorieSocioProfessionnelleApi = new CategorieSocioProfessionnelleApi(
    new Configuration(configTiers),
);
export const civilitesApi = new CivilitesApi(new Configuration(configTiers));
export const portefeuilleApi = new PortefeuilleApi(new Configuration(configTiers));

export const logoSpecificationsApi = new LogoApi(new Configuration(configTiers));

export const utilisateurApi = new UtilisateurApi(new Configuration(configUtilisateurs));

export const {
    prescripteurSlice,
    prescripteurDataSourcesThunks,
    fournisseurSlice,
    fournisseurDataSourcesThunks,
    tiersFournisseurSlice,
    tiersFournisseurDataSourcesThunks,
    paysSlice,
    cspOptionsSlice,
    walletOptionsSlice,
    paysDataSourcesThunks,
    civilitiesSlice,
    walletOptionsDataSourcesThunks,
    cspOptionsDataSourcesThunks,
    civilitiesDataSourcesThunks,
} = createTiersReducer(configTiers);

/**
 * Mail
 */
const configMail: MailConfigurationParameters = {
    basePath: APIENV.apis.mail[getCustomiserState().api.mail],
    accessToken: updateToken,
};

export const mailConfiguration = new Configuration(configMail);

/**
 * Achats
 */

export const achatsConfiguration = new Configuration(configAchats);

export const mailApi = new MailApi(mailConfiguration);
export const workflowMailApi = new WorkflowMailApi(achatsConfiguration);
export const mediaObjectApi = new MediaObjectApi(achatsConfiguration);
export const mediaObjectNoteApi = new MediaObjectNoteApi(achatsConfiguration);
export const statementApi = new ReleveAchatApi(achatsConfiguration);
export const factureAchatApi = new FactureAchatApi(achatsConfiguration);
export const factureAchatLigneApi = new FactureAchatLigneApi(achatsConfiguration);
export const factureAchatNoteApi = new FactureAchatNoteApi(achatsConfiguration);

export const { categorieStatsSlice, categorieStatsDataSourcesThunks } =
    createCategorieStatsReducer(configAchats);

export const { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks } =
    createCodeRejetStatsReducer(configAchats);

export const { invoiceStatementSlice, invoiceStatementDataSourcesThunks } =
    createReleveAchatReducer(configAchats);

export const { factureAchatSlice, factureAchatDataSourcesThunks } =
    createFactureAchatReducer(configAchats);

export const { mediaobjectSlice, mediaobjectDataSourcesThunks } =
    createMediaobjectReducer(configAchats);

/**
 * Dossiers
 */

export const dossiersConfiguration = new Configuration(configDossiers);

export const dossierApi = new DossierApi(dossiersConfiguration);
export const tauxTvaApi = new TauxTvaApi(dossiersConfiguration);

/**
 * Offre
 */

// export const offerConfiguration = new Configuration(configOffre);

// export const offersApi = new OffresApi(offerConfiguration);
// export const catalogsApi = new CatalogueApi(offerConfiguration);
// export const articlesApi = new ArticlesApi(offerConfiguration);
// export const domaineApi = new DomainesApi(offerConfiguration);
// export const additionalFieldApi = new ChampComplmentaireApi(offerConfiguration);
// export const actionApi = new ActionApi(offerConfiguration);
// export const actionDetailApi = new ActionDetailApi(offerConfiguration);
// export const packageApi = new PackageApi(offerConfiguration);
// export const originApi = new OriginesApi(offerConfiguration);
// export const offerOptionsApi = new OptionsApi(offerConfiguration);
// export const offerAdditionalFieldApi = new OfferAdditionalFieldApi(offerConfiguration);
// export const groupementApi = new GroupementApi(offerConfiguration);
// export const documentsApi = new TypeDeDocumentApi(offerConfiguration); // Moved in model configuration
// export const vatApi = new TypeTvaApi(offerConfiguration);
// export const domainApi = new DomainesApi(offerConfiguration);

// export const {
//     offersSlice,
//     offersDataSourcesThunks,
//     catalogsSlice,
//     packagesSlice,
//     articlesSlice,
//     actionsSlice,
//     offerOptionsSlice,
//     offerOptionValuesSlice,
//     offerGroupsSlice,
//     offerAdditionalFieldsSlice,
//     documentsSlice,
//     vatsSlice,
//     domainsSlice,
//     catalogsDataSourcesThunks,
//     packagesDataSourcesThunks,
//     actionsDataSourcesThunks,
//     articlesDataSourcesThunks,
//     offerOptionsDataSourcesThunks,
//     offerAdditionalFieldsDataSourcesThunks,
//     offerOptionValuesDataSourcesThunks,
//     offerGroupsDataSourcesThunks,
//     documentsDataSourcesThunks,
//     vatsDataSourcesThunks,
//     domainsDataSourcesThunks,
// } = createOfferReducer(configOffre);

// export const offerDatasourceThunks = {
//     offersDataSourcesThunks,
//     catalogsDataSourcesThunks,
//     packagesDataSourcesThunks,
//     articlesDataSourcesThunks,
//     offerOptionsDataSourcesThunks,
//     offerAdditionalFieldsDataSourcesThunks,
// };

/*
 * Formalités
 */
const configFormalites: FormaliteConfigurationParameters = {
    basePath: APIENV.apis.formalite[getCustomiserState().api.formalite],
    accessToken: updateToken,
};

export const formalitesConfiguration = new Configuration(configFormalites);

export const { commandeSlice, commandeDataSourcesThunks } = createCommandeReducer(configDossiers);

export const { dossierSlice, dossierDataSourcesThunks } = createDossierReducer(configDossiers);

export const { factureVenteSlice, factureVenteDataSourcesThunks } =
    createFactureVenteReducer(configDossiers);

export const { journalSlice, journalDataSourcesThunks } = createJournalReducer(configDossiers);

export const { rubriqueFacturationSlice, rubriqueFacturationDataSourcesThunks } =
    createRubriqueFacturationReducer(configDossiers);

export const { tauxTvaSlice, tauxTvaDataSourcesThunks } = createTauxTvaReducer(configDossiers);

// Generate Redux Store Structure
export const rootReducer = combineReducers({
    [CUSTOMIZER_REDUCER_NAME]: CustomizerReducer,
    factureForm: FactureFormReducer,
    [ACHATS_REDUCER_NAME]: combineReducers({
        [CATEGORIE_STATS_SLICE_NAME]: categorieStatsSlice.reducer,
        [CODE_REJET_STATS_SLICE_NAME]: codeRejetStatsSlice.reducer,
        [INVOICE_STATEMENT_SLICE_NAME]: invoiceStatementSlice.reducer,
        [FACTURES_ACHATS_SLICE_NAME]: factureAchatSlice.reducer,
        [MEDIAOBJECT_SLICE_NAME]: mediaobjectSlice.reducer,
    }),
    [DOSSIERS_REDUCER_NAME]: combineReducers({
        [COMMANDES_SLICE_NAME]: commandeSlice.reducer,
        [DOSSIERS_SLICE_NAME]: dossierSlice.reducer,
        [FACTURE_VENTE_SLICE_NAME]: factureVenteSlice.reducer,
        [JOURNAL_SLICE_NAME]: journalSlice.reducer,
        [RUBFACT_SLICE_NAME]: rubriqueFacturationSlice.reducer,
        [TXTVA_SLICE_NAME]: tauxTvaSlice.reducer,
    }),
    [FORMALITES_REDUCER_NAME]: combinedFormaliteReducer,
    [FORMULAIRE_REDUCER_NAME]: combinedFormulaireReducer,
    [TIERS_REDUCER_NAME]: combineReducers({
        [FOURNISSEUR_SLICE_NAME]: fournisseurSlice.reducer,
        [PRESCRIPTEUR_SLICE_NAME]: prescripteurSlice.reducer,
        [TIERS_FOURNISSEUR_SLICE_NAME]: tiersFournisseurSlice.reducer,
        [PAYS_SLICE_NAME]: paysSlice.reducer,
        [CIVILITIES_SLICE_NAME]: civilitiesSlice.reducer,
        [CSP_OPTIONS_SLICE_NAME]: cspOptionsSlice.reducer,
        [WALLET_OPTIONS_SLICE_NAME]: walletOptionsSlice.reducer,
    }),
    [OFFRE_REDUCER_NAME]: combinedOfferReducers,
    [FACTURATION_REDUCER_NAME]: combinedFactureFormaliteReducer,
    [SIGNATURE_REDUCER_NAME]: combinedDossierSignatureReducer,
    routing: RoutingReducer,
});

export const rootStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(asyncDispatchMiddleware),
});

export type RootStateType = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector;

/**
 * FactureForm
 */
export const FactureFormSelector = (s: RootStateType) => s.factureForm;

export const RoutingSelector = (s: RootStateType) => s.routing.routing;

/**
 * Tiers
 */
export const FournisseurSelector = (s: RootStateType) => s.tiers.fournisseur;
export const getFournisseurs = fournisseurDataSourcesThunks.main.getData;
export const selectFournisseur = fournisseurDataSourcesThunks.main.getSelected;
export const getFournisseurAc = fournisseurDataSourcesThunks.autocompleteFactures.getData;
export const selectFournisseurAc = fournisseurDataSourcesThunks.autocompleteFactures.getSelected;

export const PrescripteursSelector = (s: RootStateType) => s.tiers.prescripteur;
export const getPrescripteurs = prescripteurDataSourcesThunks.main.getData;
export const selectPrescripteur = prescripteurDataSourcesThunks.main.getSelected;
export const getExportPrescripteurs = prescripteurDataSourcesThunks.export.getData;
export const selectExportPrescripteurs = prescripteurDataSourcesThunks.export.getSelected;

export const TiersFournisseurSelector = (s: RootStateType) => s.tiers.tiersFournisseur;
export const getTiersFournisseurs = tiersFournisseurDataSourcesThunks.main.getData;
export const selectTiersFournisseur = tiersFournisseurDataSourcesThunks.main.getSelected;

export const PaysSelector = (s: RootStateType) => s[TIERS_REDUCER_NAME][PAYS_SLICE_NAME];
export const getPays = paysDataSourcesThunks.main.getData;
export const selectPays = paysDataSourcesThunks.main.getSelected;

export const CivilitiesSelector = (s: RootStateType) =>
    s[TIERS_REDUCER_NAME][CIVILITIES_SLICE_NAME];
export const getCivilities = civilitiesDataSourcesThunks.main.getData;
export const selectCivilities = civilitiesDataSourcesThunks.main.getSelected;

export const WalletOptionsSelector = (s: RootStateType) =>
    s[TIERS_REDUCER_NAME][WALLET_OPTIONS_SLICE_NAME];
export const getWalletOptions = walletOptionsDataSourcesThunks.main.getData;
export const selectWalletOptions = walletOptionsDataSourcesThunks.main.getSelected;

export const CspOptionsSelector = (s: RootStateType) =>
    s[TIERS_REDUCER_NAME][CSP_OPTIONS_SLICE_NAME];
export const getCspOptions = cspOptionsDataSourcesThunks.main.getData;
export const selectCspOptions = cspOptionsDataSourcesThunks.main.getSelected;

type OptionsStore = (store: RootStateType) => {
    countries: TblPaysJsonldPaysRead[];
    cspOptions: CategorieSocioProfessionnelleJsonldTiersRead[];
    walletOptions: PortefeuilleJsonldTiersRead[];
    civilities: TblParametreReferencesCiviliteJsonldCiviliteReadCollection[];
};

export const OptionsSelector: OptionsStore = (store: RootStateType) => ({
    countries: store[TIERS_REDUCER_NAME][PAYS_SLICE_NAME].main.data,
    cspOptions: store[TIERS_REDUCER_NAME][CSP_OPTIONS_SLICE_NAME].main.data,
    walletOptions: store[TIERS_REDUCER_NAME][WALLET_OPTIONS_SLICE_NAME].main.data,
    civilities: store[TIERS_REDUCER_NAME][CIVILITIES_SLICE_NAME].main.data,
});
export const OptionsLoaded = (store: RootStateType) => {
    type OptionSlice =
        | typeof PAYS_SLICE_NAME
        | typeof CSP_OPTIONS_SLICE_NAME
        | typeof WALLET_OPTIONS_SLICE_NAME
        | typeof CIVILITIES_SLICE_NAME;

    const optionSlicesNames = [
        PAYS_SLICE_NAME,
        CSP_OPTIONS_SLICE_NAME,
        WALLET_OPTIONS_SLICE_NAME,
        CIVILITIES_SLICE_NAME,
    ];

    // eslint-disable-next-line consistent-return
    optionSlicesNames.forEach((sliceName: OptionSlice) => {
        const sliceStatus = store[TIERS_REDUCER_NAME][sliceName].main.status;
        if (sliceStatus !== 'succeeded') {
            return false;
        }
    });

    return true;
};

export const getFromSpecifiedDataSourceThunks = <T, A extends string>(
    dataSourcesThunks: DataSourcesThunksType<T>,
    dataSourceName: A,
) => dataSourcesThunks[dataSourceName].getData;

/**
 * !! Currently not generic because ViewFunctionMap and ViewName are strongly associated to MediaObject !!
 */
export const getSpecifiedDataSourceByViewName = <T, A extends ViewName>(
    dataSourcesThunks: DataSourcesThunksType<T>,
    dataSourceNameFromView: A,
) =>
    getFromSpecifiedDataSourceThunks(
        dataSourcesThunks,
        `${ViewFunctionMap[dataSourceNameFromView]}`,
    );

/**
 * Achats
 */
export const CategorieStatsSelector = (s: RootStateType) => s.achats.categoriestats;
export const getCategorieStats = categorieStatsDataSourcesThunks.main.getData;
export const selectCategorieStats = categorieStatsDataSourcesThunks.main.getSelected;

export const CodeRejetStatsSelector = (s: RootStateType) => s.achats.coderejetstats;
export const getCodeRejetStats = codeRejetStatsDataSourcesThunks.main.getData;
export const selectCodeRejetStats = codeRejetStatsDataSourcesThunks.main.getSelected;

export const InvoiceStatementSelector = (s: RootStateType) => s.achats.statement;
export const getInvoiceStatement = invoiceStatementDataSourcesThunks.main.getData;
export const selectInvoiceStatement = invoiceStatementDataSourcesThunks.main.getSelected;

export const FactureachatSelector = (s: RootStateType) => s.achats.factureachat;
export const getFactureAchat = factureAchatDataSourcesThunks.main.getData;
export const selectFactureAchat = factureAchatDataSourcesThunks.main.getSelected;

export const getFactureAchatFromStatement =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME].getData;
export const selectFactureAchatFromStatement =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME].getSelected;

export const getFactureAchatPause =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_PAUSE_DATASOURCE_NAME].getData;
export const selectFactureAchatPause =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getSelected;

export const getFactureAchatStats =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getData;
export const selectFactureAchatStats =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getSelected;

export const MediaObjectSelector = (s: RootStateType) => s.achats.mediaobject;

export const getMediaObjectByViewName = <T extends ViewName>(dataSourceName: T) =>
    getSpecifiedDataSourceByViewName(mediaobjectDataSourcesThunks, dataSourceName);

export const getMediaObject = mediaobjectDataSourcesThunks.main.getData;
export const selectMediaObject = mediaobjectDataSourcesThunks.main.getSelected;

/**
 * Dossiers
 */

export const CommandeSelector = (s: RootStateType) => s.dossiers.commande;
export const getCommande = commandeDataSourcesThunks.main.getData;
export const selectCommande = commandeDataSourcesThunks.main.getSelected;

export const DossierSelector = (s: RootStateType) => s.dossiers.dos;
export const getDossier = dossierDataSourcesThunks.main.getData;
export const selectDossier = dossierDataSourcesThunks.main.getSelected;
export const selectAutocompleteDossier = dossierDataSourcesThunks.autocompleteDossiers.getSelected;
export const getAutocompleteDossier = dossierDataSourcesThunks.autocompleteDossiers.getData;

export const FactureVenteSelector = (s: RootStateType) => s.dossiers.facturevente;
export const getFactureVente = factureVenteDataSourcesThunks.main.getData;
export const selectFactureVente = factureVenteDataSourcesThunks.main.getSelected;

export const JournalSelector = (s: RootStateType) => s.dossiers.jal;
export const getJournal = journalDataSourcesThunks.main.getData;
export const selectJournal = journalDataSourcesThunks.main.getSelected;

export const RubriqueFacturationSelector = (s: RootStateType) => s.dossiers.rubfac;
export const getRubFac = rubriqueFacturationDataSourcesThunks.main.getData;
export const selectRubFac = rubriqueFacturationDataSourcesThunks.main.getSelected;

export const TauxTvaSelector = (s: RootStateType) => s.dossiers.txtva;
export const getTauxTva = tauxTvaDataSourcesThunks.main.getData;
export const selectTauxTva = tauxTvaDataSourcesThunks.main.getSelected;

// [OFFRE] - Store accessors
export const OfferSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][OFFERS_SLICE_NAME];
export const CatalogSelector = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][CATALOGS_SLICE_NAME];
export const PackageSelector = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][PACKAGES_SLICE_NAME];
export const ArticleSelector = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][ARTICLES_SLICE_NAME];
export const OfferOptionsSelector = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_OPTIONS_SLICE_NAME];
export const OfferAdditionalFieldsSelector = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_ADDITIONAL_FIELDS_SLICE_NAME];
export const DocumentSelector = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][DOCUMENT_SLICE_NAME];
export const vatSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][VATS_SLICE_NAME];
export const domainSelector = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][DOMAINS_SLICE_NAME];

// [OFFRE] - Store main selected accessors
export const selectedOffer = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][OFFERS_SLICE_NAME].main.selected;
export const selectedCatalog = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][CATALOGS_SLICE_NAME].main.selected;
export const selectedPackage = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][PACKAGES_SLICE_NAME].main.selected;
export const selectedArticle = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][ARTICLES_SLICE_NAME].main.selected;
export const selectedOfferOption = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_OPTIONS_SLICE_NAME].main.selected;
export const selectedOfferAdditionalField = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][OFFER_ADDITIONAL_FIELDS_SLICE_NAME].main.selected;

export default rootStore;
